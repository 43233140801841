import React, { useEffect, useRef } from "react"
import { Grid } from "./styles"

import ServerList from "../ServerList"
import ServerName from "../ServerName"
import ChannelInfo from "../ChannelInfo"
import ChannelList from "../ChannelList"
import UserInfo from "../UserInfo"
import ChannelData from "../ChannelData"
import UserList from "../UserList"
import _, { forEach } from "underscore"
import WelcomeModal from "../WelcomeModal/WelcomeModal"
import { SimpleMessage } from "../../SimpleMessage"

import langchain from "../../assets/images/servers/langchain.webp"
import alchemy from "../../assets/images/servers/alchemy.webp"

import * as Avatars from "../../lib/Avatars/Avatars"

import ChannelMessage, { Mention } from "../ChannelMessage"

import "highlight.js/styles/hybrid.css"

import { useLLM } from "../../hooks/UseLLM/UseLLM"

// @ts-ignore
import Highlight from "react-highlight"
import { messageToString } from "../../lib/Helpers/Helpers"

import hljs from "highlight.js/lib/core"

import { useFirebase } from "../../hooks/UseFirebase/UseFirebase"

import { v4 as uuid } from "uuid"

import javascript from "highlight.js/lib/languages/javascript"
import typescript from "highlight.js/lib/languages/typescript"
import python from "highlight.js/lib/languages/python"
import java from "highlight.js/lib/languages/java"
import c from "highlight.js/lib/languages/c"
import php from "highlight.js/lib/languages/php"
import csharp from "highlight.js/lib/languages/csharp"
import css from "highlight.js/lib/languages/css"
import ReactMarkdown from "react-markdown"

hljs.registerLanguage("javascript", javascript)
hljs.registerLanguage("typescript", typescript)
hljs.registerLanguage("python", python)
hljs.registerLanguage("java", java)
hljs.registerLanguage("c", c)
hljs.registerLanguage("php", php)
hljs.registerLanguage("csharp", csharp)
hljs.registerLanguage("css", css)

const MAX_HISTORY_MESSAGES = 5

var sessionId = uuid()
const { logEvent, initialize } = useFirebase()
initialize(sessionId)

export type MessageAuthor = {
    nickname: string
    avatar: string
    role: string
    isBot: boolean
}

export type Message = {
    id: string
    author: MessageAuthor
    date: string
    content: string | React.ReactElement | React.ReactNode
    hasMention?: boolean
}

type LLMResponse = {
    answer: string
}

export const getMember = (nickname: string): MessageAuthor => {
    const database = [
        Avatars.Glime,
        Avatars.AryaStark,
        Avatars.CerseiLannister,
        Avatars.DaenerysTargaryen,
        Avatars.JonSnow,
        Avatars.NedStark,
        Avatars.JackieRobinson,
        Avatars.Harry,
        Avatars.Hermione,
        Avatars.Ron,
        Avatars.Draco,
        Avatars.Snape,
        Avatars.Minerva,
        Avatars.Nibbler,
        Avatars.Bender,
        Avatars.Hubert,
        Avatars.Phillip,
        Avatars.Turanga,
        Avatars.Doctor,
        Avatars.Zapp,
        Avatars.Seinfeld,
        Avatars.Eleine,
        Avatars.Kramer,
        Avatars.Newman,
        Avatars.Soup,
    ]

    console.log(
        database.filter((member) => member.nickname === nickname)[0] ?? null
    )

    return database.filter((member) => member.nickname === nickname)[0] ?? null
}

const servers = [
    {
        actualInitialChatMessageIndex: -1,
        hasNotifications: false,
        mentions: 0,
        id: "1",
        name: "Open AI",
        image: "https://cdn.discordapp.com/icons/974519864045756446/d7ec4ed5884437bae0333aa345a97160.webp?size=96",
        namespace: "openai",
        channels: [
            {
                name: "general",
            },
            {
                name: "announcements",
            },
            {
                name: "support",
                selected: true,
            },
            {
                name: "feature-requests",
            },
            {
                name: "off-topic",
            },
        ],
        members: [
            Avatars.Glime,
            Avatars.JackieRobinson,
            Avatars.AryaStark,
            Avatars.CerseiLannister,
            Avatars.DaenerysTargaryen,
            Avatars.JonSnow,
            Avatars.NedStark,
        ],
        messages: [
            {
                id: "1",
                author: getMember("Arya Stark"),
                date: "02/06/2023",
                content: (
                    <>
                        <Mention>@Glime</Mention>, How can I integrate OpenAI
                        chat completion with my Python application?
                    </>
                ),
            },
            {
                id: "2",
                author: getMember("Glime"),
                date: "02/06/2023",
                content: (
                    <>
                        Hello, <Mention>@AryaStark</Mention>! Give me a few
                        seconds, I'm looking for the answer.
                    </>
                ),
                hasMention: false,
            },
            {
                id: "3",
                author: getMember("Glime"),
                date: "02/06/2023",
                content: (
                    <>
                        <p>
                            To integrate with OpenAI with Python, you'll first
                            need to install it's package:
                        </p>

                        <p>
                            <Highlight language="python">
                                {`from langchain.embeddings.openai import OpenAIEmbeddings
from langchain.text_splitter import CharacterTextSplitter
from langchain.vectorstores import Chroma
from langchain.chains.question_answering import load_qa_chain
from langchain.llms import OpenAI

# Load the book content
with open("your_book_file.txt") as f:
    book_content = f.read()

# Split the book's content into smaller chunks
text_splitter = CharacterTextSplitter(chunk_size=1000, chunk_overlap=0)
texts = text_splitter.split_text(book_content)

# Create embeddings and a document retrieval system
embeddings = OpenAIEmbeddings()
docsearch = Chroma.from_texts(texts, embeddings).as_retriever()

# Define your query
query = "What does the main character think about love?"

# Get relevant documents based on query
docs = docsearch.get_relevant_documents(query)

# Use a question answering model to generate an answer
llm = OpenAI()
qa_chain = load_qa_chain(llm)
answer = qa_chain.generate(query, docs)

print(answer)`}
                            </Highlight>
                        </p>
                    </>
                ),
                continuation: true,
            },
            {
                id: "4",
                author: getMember("Glime"),
                date: new Date().toLocaleDateString(),
                content: (
                    <>
                        <Mention>@Jackie Robinson</Mention>, I'm Glime! Your
                        code assistant buddy. and this is an experimental
                        environment. Feel free to ask me any question related to
                        the current server where in.
                    </>
                ),
                hasMention: true,
            },
        ] as Message[],
    },
    {
        actualInitialChatMessageIndex: -1,
        hasNotifications: false,
        mentions: 0,
        id: "2",
        name: "LangChain",
        image: langchain,
        namespace: "langchain-test-splitter",
        channels: [
            {
                name: "general",
            },
            {
                name: "announcements",
            },
            {
                name: "support",
                selected: true,
            },
            {
                name: "feature-requests",
            },
            {
                name: "off-topic",
            },
        ],
        members: [
            Avatars.Bender,
            Avatars.Glime,
            Avatars.Phillip,
            Avatars.Doctor,
            Avatars.Zapp,
            Avatars.Nibbler,
            Avatars.Turanga,
            Avatars.Hubert,
            Avatars.JackieRobinson,
        ],
        messages: [
            {
                id: "1",
                author: getMember("Nibbler"),
                date: "02/02/2023",
                content: (
                    <>
                        <Mention>@Glime</Mention>, can you provide me a snippet
                        of Langchain being used for a conversational flow?
                        Please add the snippet in Python. ?
                    </>
                ),
            },
            {
                id: "2",
                author: getMember("Glime"),
                date: "02/02/2023",
                content: (
                    <>
                        <p>
                            Sure <Mention>@Nibbler</Mention>! Here's an example
                            snippet for using Langchain for conversational flow:
                        </p>

                        <p>
                            <Highlight language="python">
                                {`from langchain.chat_models import ChatOpenAI
from langchain.schema import HumanMessage
# Set up the chat model
chat = ChatOpenAI()
# Define a list of possible conversation starters
starters = [
    "Hey, how's it going?",
    "What's up?",
    "How's your day been?",
]
# Loop indefinitely until user stops conversation
while True:
    # Wait for user input and prompt if no input given
    user_input = input("You: ") or "hello"
    # Pass user input to chat model and get response
    response = chat([HumanMessage(content=user_input)])
    # Print response and prompt for more input
    print(f"Bot: {response.content}")
    print("Bot: What else would you like to chat about?")`}
                            </Highlight>
                        </p>

                        <p>
                            This code sets up a chat model using Langchain's
                            `ChatOpenAI` class and defines a list of possible
                            conversation starters. It then enters an indefinite
                            loop where it waits for user input (or defaults to
                            "hello" if no input is provided), passes the input
                            to the chat model, and gets a response back. It then
                            prints the response and prompts the user for more
                            input.
                        </p>

                        <p>
                            Note: In order for this code to work, you'll need to
                            have set up an API key for Langchain's GPT-4 model
                            and included it in your environment variables or in
                            the `langchain/config.py` file.
                        </p>
                    </>
                ),
                continuation: false,
            },
            {
                id: "3",
                author: getMember("Glime"),
                date: new Date().toLocaleDateString(),
                content: (
                    <>
                        <Mention>@Jackie Robinson</Mention>, I'm Glime! Your
                        code assistant buddy. and this is an experimental
                        environment. Feel free to ask me any question related to
                        the current server where in.
                    </>
                ),
                hasMention: true,
            },
        ] as Message[],
    },
    {
        actualInitialChatMessageIndex: -1,
        hasNotifications: false,
        mentions: 0,
        id: "3",
        name: "Alchemy",
        image: alchemy,
        namespace: "alchemy",
        channels: [
            {
                name: "general",
            },
            {
                name: "announcements",
            },
            {
                name: "support",
                selected: true,
            },
            {
                name: "feature-requests",
            },
            {
                name: "off-topic",
            },
        ],
        members: [
            Avatars.Glime,
            Avatars.Harry,
            Avatars.Hermione,
            Avatars.Ron,
            Avatars.Draco,
            Avatars.Snape,
            Avatars.Minerva,
            Avatars.JackieRobinson,
        ],
        messages: [
            {
                id: "0",
                author: getMember("Draco Malfoy"),
                date: "02/06/2023",
                content: (
                    <>
                        <Mention>@Glime</Mention>, how can I use Alchemy to
                        fetch all NFTs from my collection?
                    </>
                ),
            },
            {
                id: "2",
                author: getMember("Glime"),
                date: "02/06/2023",
                content: (
                    <>
                        <p>
                            <Mention>@Draco Malfoy</Mention>, You can use
                            Alchemy's NFT API to fetch all NFTs from your
                            collection. Alchemy's NFT API allows you to quickly
                            get all the information you need to know about NFTs,
                            including Ethereum and Polygon, by making one
                            request to fetch specific NFT information for both
                            ERC-721 and ERC-1155 tokens. Here is an example code
                            to fetch all NFTs owned by an address:
                        </p>

                        <p>
                            <Highlight language="javascript">
                                {`const config = {
  apiKey: "<-- ALCHEMY APP API KEY -->",
  network: Network.ETH_MAINNET,
};
const alchemy = new Alchemy(config);
const main = async () => {
  const address = "elanhalpern.eth";
  const nfts = await alchemy.nft.getNftsForOwner(address);
  console.log(nfts);
};
main();`}
                            </Highlight>
                        </p>

                        <p>
                            You can also check out the documentation on how to
                            get all NFTs owned by an address on Alchemy's
                            website:
                        </p>

                        <p>
                            <a href="https://docs.alchemy.com/docs/how-to-get-all-nfts-owned-by-an-address">
                                https://docs.alchemy.com/docs/how-to-get-all-nfts-owned-by-an-address
                            </a>
                        </p>
                    </>
                ),
                continuation: false,
            },
            {
                id: "3",
                author: getMember("Glime"),
                date: new Date().toLocaleDateString(),
                content: (
                    <>
                        <Mention>@Jackie Robinson</Mention>, I'm Glime! Your
                        code assistant buddy. and this is an experimental
                        environment. Feel free to ask me any question related to
                        the current server where in.
                    </>
                ),
                hasMention: true,
            },
        ] as Message[],
    },
    {
        actualInitialChatMessageIndex: -1,
        hasNotifications: false,
        mentions: 0,
        id: "4",
        name: "Web3Auth",
        image: "https://cdn.discordapp.com/icons/581391194370605056/2e86e31e96a58120e75b3f86691cdafa.webp?size=96",
        namespace: "web3auth",
        channels: [
            {
                name: "general",
            },
            {
                name: "announcements",
            },
            {
                name: "support",
                selected: true,
            },
            {
                name: "feature-requests",
            },
            {
                name: "off-topic",
            },
        ],
        members: [
            Avatars.Glime,
            Avatars.JackieRobinson,
            Avatars.AryaStark,
            Avatars.CerseiLannister,
            Avatars.DaenerysTargaryen,
            Avatars.JonSnow,
            Avatars.NedStark,
        ],
        messages: [
            // {
            //     id: "1",
            //     author: getMember("Arya Stark"),
            //     date: "02/06/2023",
            //     content: (
            //         <>
            //             <Mention>@Glime</Mention>, can you give me the firsts
            //             steps to integrate my game with Moonstream APIs?
            //         </>
            //     ),
            // },
            // {
            //     id: "3",
            //     author: getMember("Glime"),
            //     date: "02/06/2023",
            //     content: (
            //         <>
            //             <p>To integrate with Moonstream APIs, you need to...</p>

            //             <p>
            //                 <Highlight language="python">{`[ADD ANSWER HERE]`}</Highlight>
            //             </p>
            //         </>
            //     ),
            //     continuation: true,
            // },
            {
                id: "4",
                author: getMember("Glime"),
                date: new Date().toLocaleDateString(),
                content: (
                    <>
                        <Mention>@Jackie Robinson</Mention>, I'm Glime! Your
                        code assistant buddy. and this is an experimental
                        environment. Feel free to ask me any question related to
                        the current server where in.
                    </>
                ),
                hasMention: true,
            },
        ] as Message[],
    },
]

servers.forEach((server) => {
    server.actualInitialChatMessageIndex = server.messages.length
})

const botGreetings = (user: string) => {
    return {
        id: String(Math.random()),
        author: getMember("Glime"),
        date: new Date().toLocaleDateString(),
        content: (
            <>
                Hold on, <Mention>{user}</Mention>! I'll answer in a couple
                seconds.
            </>
        ),
        hasMention: true,
    }
}

const botAnswer = (answer: string) => {
    return {
        id: String(Math.random()),
        author: getMember("Glime"),
        date: new Date().toLocaleDateString(),
        content: answer,
        continuation: false,
        isHTML: true,
    }
}

const Layout: React.FC = () => {
    const [selectedServer, setSelectedServer] = React.useState(servers[0])
    const [lastUpdate, setLastUpdate] = React.useState(String)
    const [showModal, setShowModal] = React.useState(true)

    const lastGreetingIdRef = useRef<string>()

    useEffect(() => {
        hljs.highlightAll()
    }, [selectedServer])

    const { fetchLLM } = useLLM()

    return (
        <>
            {showModal && (
                <WelcomeModal
                    onCloseModal={() => {
                        logEvent("modal_closed", {
                            sessionId: sessionId,
                        })
                        setShowModal(false)
                    }}
                ></WelcomeModal>
            )}
            <Grid>
                <ServerList
                    onServerSelected={(id) =>
                        setSelectedServer(
                            servers.filter((server) => server.id === id)[0]
                        )
                    }
                    servers={servers}
                />
                <ServerName name={selectedServer.name} />
                <ChannelInfo />
                <ChannelList channels={selectedServer.channels} />
                <UserInfo />
                <ChannelData
                    onSendMessage={(message, cb) => {
                        selectedServer.messages.push({
                            id: String(Math.random()),
                            author: getMember("Jackie Robinson"),
                            date: new Date().toLocaleDateString(),
                            content: message,
                        })

                        setLastUpdate(String(Math.random()))

                        setTimeout(() => {
                            cb()

                            setTimeout(async () => {
                                const historyMessages: SimpleMessage[] = []

                                selectedServer.messages
                                    .slice(
                                        selectedServer.actualInitialChatMessageIndex -
                                            1
                                    )
                                    .reverse()
                                    .slice(1, MAX_HISTORY_MESSAGES)
                                    .forEach((message: Message) => {
                                        historyMessages.push({
                                            author: message.author.isBot
                                                ? "BOT"
                                                : "USER",
                                            content: messageToString(
                                                message.content
                                            ),
                                        })
                                    })

                                const greetingMessage = botGreetings(
                                    `@${getMember("Jackie Robinson").nickname}`
                                )

                                lastGreetingIdRef.current = greetingMessage.id

                                // add greeting from bot
                                selectedServer.messages.push(greetingMessage)

                                setLastUpdate(String(Math.random()))

                                cb()

                                let m: LLMResponse = await fetchLLM(
                                    selectedServer.namespace!!,
                                    message,
                                    sessionId,
                                    historyMessages
                                )

                                // Remove last greeting message
                                selectedServer.messages =
                                    selectedServer.messages.filter(
                                        (message) =>
                                            message.id !==
                                            lastGreetingIdRef.current
                                    )
                                selectedServer.messages.push(
                                    botAnswer(m.answer)
                                )

                                setLastUpdate(String(Math.random()))

                                hljs.highlightAll()

                                cb()
                            }, 500)

                            // call backend

                            // add response from bot
                        }, 200)
                    }}
                    messages={selectedServer.messages}
                />
                <UserList members={selectedServer.members} />
            </Grid>
        </>
    )
}

export default Layout
